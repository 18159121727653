<template>
  <div>
    <!-- 头部 -->
    <tobs ref="citynews"></tobs>
    <!-- 中间 -->
    <div class="citynews-box">
      <div class="citynews-left">
        <div class="left-box">
          <!-- for循环tab栏 -->
          <div  class="citynews-tab" >
            <div class="center-box">
              <div class="tabs" v-for="(item, index) in tabs" :key="index">
                <span
                  :class="xian1 == index ? 'xian' : ''"
                  @click="xianshi1(index)"
                  >{{ item.name }}</span
                >
              </div>
            </div>
          </div>
        </div>
        <div class="left-box1"></div>
      </div>
      <div class="citynews-rit">
        <div class="rit-box"></div>
        <div class="rit-box1"></div>
      </div>
    </div>

    
    <!-- 底部 -->
    <foots></foots>
  </div>
</template>

<script>
import tobs from '../../../components/hometitle/hometitle.vue'
import foots from '../../../components/foot/foot.vue'
export default {
  components: {
    tobs,
    foots
  },
  data() {
    return {
      xian1: 0,
      tabs: [
        { name: '最新' },
        { name: '热门' },
        { name: '新视野' },
        { name: '新技术' },
        { name: '新材料' },
        { name: '新工艺' }
      ]
    }
  },
  computed: {},
  created() {},
  mounted() {
    this.$refs.citynews.getindex(0)
  },
  methods: {
    xianshi(index) {
      this.xian = index
    },
    xianshi1(index) {
      this.xian1 = index
    }
  }
}
</script>

<style scoped lang="scss">
.citynews-box {
  display: flex;
  width: 1200px;
  // height: 500px;
  // background: red;
  margin: 0 auto;
  justify-content: space-between;
}
.citynews-left {
  width: 876px;
  height: 100%;
  // background: #fff;
  border-radius: 5px;
  .left-box {
    width: 881px;
    height: 554px;
    background: rgba(255, 255, 255, 1);
    border-radius: 5px;
  }
  .left-box1 {
    margin-top: 14px;
    width: 881px;
    height: 554px;
    background: rgba(255, 255, 255, 1);
    border-radius: 5px;
  }
}
.citynews-rit {
  width: 303px;
  height: 100%;
  // background: #fff;
  .rit-box {
    height: 200px;
    background: #ffffff;
    border-radius: 5px;
  }
  .rit-box1 {
    height: 200px;
    margin-top: 20px;
    background: #ffffff;
    border-radius: 5px;
  }
}
.citynews-tab{
  width: 881px;
  height: 43px;
  background: #f5f5f5;
}

.center-box {
  display: flex;
  margin-left: 133px;
  margin-top: 30px;

  .tabs {
    margin-right: 60px;
  color: rgba(153, 153, 153, 1);
    font-size: 16px;
    line-height: 43px;
    float: left;
    height: 100%;
  }
  .xian {
    margin-top: 11px;
    width: 24px;
    height: 100%;
    border-radius: 1px;
    margin: 11px auto;
    background-color: rgba(255, 255, 255, 1);
    background: rgba(255, 255, 255, 1);
  }
}
</style>
